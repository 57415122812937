import React, { Component } from 'react';
import './App.css';

//my components
import HeroVideoOutput from './components/HeroVideo/HeroVideo';
import FeaturedWork from './components/FeaturedWork/FeaturedWork';
import FeaturedVideos from './components/FeaturedVideos/FeaturedVideos';
import BlockQuotes from './components/BlockQuote/BlockQuote';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Tooltip from './components/Tooltip/Tooltip';
import DelayedLoadingMessage from './components/DelayedLoadingMessage/';

import Reward from 'react-rewards'; //confetti
import ReactGA from 'react-ga'; // google analytics
import LazyLoad from 'react-lazyload';
import { BrowserRouter as Router, Route } from "react-router-dom";


const HomePage = React.lazy(() => import('./pages/HomePage'));
const ClientWork = React.lazy(() => import('./pages/ClientWork'));


//import ClientWork from './pages/ClientWork/ClientWork';
/*
import LandingPage from 'pages/LandingPage';
import AboutPage from 'pages/AboutPage';
import PortfolioPage from 'pages/PortfolioPage';
import ParallaxBackground from 'components/ParallaxBackground';
import ScrollTop from 'components/ScrollTop';
import ThemeSwitcher from 'components/ThemeSwitcher';
import Footer from 'components/Footer';
*/
class App extends Component {
  constructor() {
    super();
  }

  componentDidMount() {

    //initializeReactGA
    ReactGA.initialize('UA-154880-5');
    ReactGA.pageview('/homepage');
  }

  render() {
    return (

        <Router basename={process.env.PUBLIC_URL}>
          <React.Suspense fallback={<DelayedLoadingMessage />}>
            <Route exact path="/" render={(props) => <HomePage {...props}  />} />
            <Route exact path="/past_work" render={(props) => <ClientWork {...props} />} />

          </React.Suspense>
        </Router>


  );
  }
}

export default App;
