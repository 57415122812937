import React, { Component } from 'react';
import './Header.css';
import Reward from 'react-rewards';
import Hamburger from '../Hamburger/Hamburger';
import TiltedMenu from '../TiltedMenu/TiltedMenu';

class Header extends Component {
    constructor() {
        super();
        this.state = { showMenu:false};
        this.toggleMenu = this.toggleMenu.bind(this);
        this.award = this.award.bind(this)
    }
    award(){
        this.reward.rewardMe();
        // to "punish" user :
        //this.reward.punishMe();
    }
    toggleMenu(){
        this.setState({ showMenu: !this.state.showMenu });

    };
    render() {
        return (
            <header>
                <div className="logo" onClick={this.award}>
                    <Reward
                        ref={(ref) => { this.reward = ref }}
                        type='confetti'
                    > </Reward>
                </div>
                <TiltedMenu show={this.state.showMenu}/>
                <Hamburger onClick={this.toggleMenu}/>
            </header>
        )
    }
}

export default Header;