import React, { Component } from 'react';
import './FeaturedVideos.css';
import Image from 'react-lazy-image';
import LazyLoad from 'react-lazyload';

class FeaturedVideos extends Component {
    render() {
        return (
            <section className="featured_videos">
            <div className="grid">
                <figure>
                    <LazyLoad height={282} once>
                        <iframe title="Making My First 3D Online Multiplayer Game in 24 Hours" className="ReactYoutube fadeInUp" width="432" height="282" src="https://www.youtube.com/embed/WXWZjTe7Z3I?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                    </LazyLoad>
                </figure>
                <figure>
                    <LazyLoad height={282} once>
                        <iframe title="Are Birds Real? - Making a Bird Simulator" className="ReactYoutube fadeInUp" width="432" height="282" src="https://www.youtube.com/embed/5sQPbd7-Lh0?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                    </LazyLoad>
                </figure>
                <figure>
                    <LazyLoad height={282} once>
                        <iframe title="I Hired People on Fiverr to Finish My Game's Music" className="ReactYoutube fadeInUp" width="432" height="282" src="https://www.youtube.com/embed/KI9ruQGsObg?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                    </LazyLoad>
                </figure>
                <figure>
                    <LazyLoad height={282} once>
                        <iframe title="Making Platforms GLOW" className="ReactYoutube fadeInUp" width="432" height="282" src="https://www.youtube.com/embed/LalqLWD1odI?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                    </LazyLoad>
                </figure>

                <div className="clear"></div>
            </div>
                </section>
        )
    }
}


export default FeaturedVideos;