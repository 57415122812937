import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class DelayedLoadingMessage extends React.Component {
    constructor(props) {
        super(props);
        this.enableMessage = this.enableMessage.bind(this);
        this.state = {
            displayMessage: false,
        };
        this.timer = setTimeout(this.enableMessage, 300);
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    enableMessage() {
        this.setState({displayMessage: true});
    }
    render() {
        const {displayMessage} = this.state;
        let {width, margin} = this.props;
        if(this.props.width==null) { width='80%'; }
        if(this.props.margin==null) { margin='20% auto 20px auto'; }
        if (!displayMessage) {
            return null;
        }
        return <LinearProgress color="secondary" style={{width:width,display:'block',textAlign:'center',margin:margin}} />;
    }
}

export default DelayedLoadingMessage;