import React from 'react';
import './HeroVideo.css';
import { Player, ControlBar } from 'video-react';

import HeroVideo from "../../videos/2018/Austin_Cameron_Video_Hero - 500px - Compressed.mp4";

export default () => {
    return (
        <Player className="√"
            fluid
            muted
            playsInline
            autoPlay
            loop
            controls={false}
        >
            <source src={HeroVideo} />
            <ControlBar>
            </ControlBar>
        </Player>
    );
};