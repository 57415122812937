import React, { Component } from 'react';
import './Footer.css';
import Reward from 'react-rewards';

class Footer extends Component {
    constructor() {
        super();
        this.award = this.award.bind(this);
    }
    award(){
        this.reward.rewardMe();
    }
    render() {
        return (
            <footer>
                <div className="sub_container">
                    <div className="top">
                        <div className="logo fadeInUp" onClick={this.award}>
                            <Reward
                                ref={(ref) => { this.reward = ref }}
                                type='confetti'
                            > </Reward>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="left copyright fadeInUp">&#x00A9; 2020 Austin Cameron</div>
                        <div className="right footer_nav">
                            <ul className="fadeInUp">
                                <li><a href="https://austincameron.com/about/">About</a></li>
                                <li><a href="https://austincameron.com/photography/">Shop</a></li>
                                <li><a href="mailto:me@austincameron.com">Contact</a></li>
                            </ul>
                            <span className="show_mobile"></span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;