import React, { Component } from 'react';
import './BlockQuote.css';

class BlockQuotes extends Component {

    render() {
        return (
            <section className="block_quote fadeInUp">
                <blockquote className="quote-box blue_1">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        Cameron has an in-depth background in technology. Before leaving to work on LiveWarm, he was director of engineering at Cuddle Clones - one of Louisville's fastest growing companies.
                    </p>
                    <hr/>
                        <div className="blog-post-actions">
                            <p className="blog-post-bottom pull-left">
                                Business Journal
                            </p>
                            <p className="blog-post-bottom pull-right">
                                <a href="https://bizjournals.com/louisville/news/2018/12/26/this-louisville-company-created-a-sweatshirt-that.html" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                            </p>
                        </div>
                </blockquote>
                <blockquote className="quote-box blue_2">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        Helping us get started on 2018 photography is Pixel enthusiast & photographer @ustincameron. He’s a regular #teampixel contributor who’s working through a personal goal of shooting a photo a day for 1,000 days—with more than 700 already under his belt!                      </p>
                    <hr/>
                    <div className="blog-post-actions">
                        <p className="blog-post-bottom pull-left">
                            Google / Team Pixel ~ CES 2018
                        </p>
                        <p className="blog-post-bottom pull-right">
                            <a href="https://www.blog.google/products/pixel/teampixel-community-member-austin-cameron-living-city/" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                        </p>
                    </div>
                </blockquote>
                <blockquote className="quote-box blue_1">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        Coming in at Number One, the Impulcity app has got you covered. It's more than a calendar of events, including articles & lots of pictures to see what it's like before you get there.
                    </p>
                    <hr/>
                        <div className="blog-post-actions">
                            <p className="blog-post-bottom pull-left">
                                WCPO, The List TV
                            </p>
                            <p className="blog-post-bottom pull-right">
                                <a href="https://thelisttv.com" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                            </p>
                        </div>
                </blockquote>
                <blockquote className="quote-box blue_2">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        Impulcity learns your preferences and searches thousands of events to recommend. It will help you find fun things to do in your city based on your personality & location.
                    </p>
                    <hr/>
                        <div className="blog-post-actions">
                            <p className="blog-post-bottom pull-left">
                                NBC's Today Show
                            </p>
                            <p className="blog-post-bottom pull-right">
                                <a href="https://today.com/money/get-lowdown-must-have-apps-all-ages-6C10241538" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                            </p>
                        </div>
                </blockquote>


                <blockquote className="quote-box blue_1">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        Some of us are just born creators. The thought of sitting still... We'd be bored. We'd rather be out and about exploring.
                        If this description fits your personality, then you'll feel like you already know Austin Cameron.
                    </p>
                    <hr/>
                        <div className="blog-post-actions">
                            <p className="blog-post-bottom pull-left">
                                Cincinnati Refined / WKRC
                            </p>
                            <p className="blog-post-bottom pull-right">
                                <a href="http://cincinnatirefined.com/travel/photographer-spotlight-austin-cameron" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                            </p>
                        </div>
                </blockquote>

                <blockquote className="quote-box blue_2">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        The Hot Dozen Showcase honored 12 of the “hottest” emerging and fast-growth businesses... Being a part of the Hot Dozen is a true honor – these are the companies with big picture thinking that are shaping the landscape.
                    </p>
                    <hr/>
                        <div className="blog-post-actions">
                            <p className="blog-post-bottom pull-left">
                                GLI / EnterpriseCorp
                            </p>
                            <p className="blog-post-bottom pull-right">
                                <a href="https://web.archive.org/web/20130205025343/http://www.greaterlouisville.com/EnterpriseCORP/PressReleases.aspx?id=6613&blogid=3922" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                            </p>
                        </div>
                </blockquote>





                <blockquote className="quote-box red hide">
                    <p className="quotation-mark">
                        “
                    </p>
                    <p className="quote-text">
                        10 Startups to Watch: Impulcity’s online platform connects people with events and venues in their city.
                    </p>
                    <hr/>
                    <div className="blog-post-actions">
                        <p className="blog-post-bottom pull-left">
                            Cincinnati Enquirer / USA Today
                        </p>
                        <p className="blog-post-bottom pull-right">
                            <a href="https://www.cincinnati.com/story/news/2014/01/05/10-startups-strikingit-big/4303325/" target="_blank" rel="noopener noreferrer" className="badge quote-badge">Read More</a>
                        </p>
                    </div>
                </blockquote>
            </section>
        )
    }
}

export default BlockQuotes;