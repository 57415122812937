import React, { Component } from 'react';
import './Hamburger.css';

class Hamburger extends Component {
    render() {
        return (
            <div>
                <input type="checkbox" id="nav-toggle"/>
                <label id="nav-toggle-label" htmlFor="nav-toggle" onClick={this.props.onClick.bind(this)}>
                    <div id="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div id="cross">
                        <span></span>
                        <span></span>
                    </div>
                </label>
            </div>
        )
    }
}

export default Hamburger;