import React, { Component } from 'react';
import './FeaturedWork.css';
import Image from 'react-lazy-image';


class FeaturedWork extends Component {
    render() {
        return (
            <section className="featured_work">
            <div className="grid">
                <figure className="effect-apollo livewarm">
                    <Image source="https://livewarm.co/wp-content/uploads/2018/06/winter_hero.png" />
                    <figcaption>
                        <h2>Live<span>Warm</span></h2>
                        <p>Patent-pending, self-adjusting heated apparel.</p>
                        <a href="https://livewarm.co/" target="_blank" rel="noopener noreferrer">View more</a>
                    </figcaption>
                </figure>
                <figure className="effect-apollo impulcity">
                    <Image source="https://austincameron.com/res/images/homepage/Impulcity.jpg" />
                    <figcaption>
                        <h2><span>Impulcity</span></h2>
                        <p>A mobile app showcasing everything happening around you.</p>
                        <a href="https://austincameron.com/">View more</a>
                    </figcaption>
                </figure>
                <figure className="effect-apollo cuddleclones">
                    <Image source="https://austincameron.com/res/images/homepage/CuddleClone.jpg" />
                    <figcaption>
                        <h2><span>Cuddle Clones</span></h2>
                        <p>Modernizing a custom-stuffed animal company.</p>
                        <a href="https://cuddleclones.com/" target="_blank" rel="noopener noreferrer">View more</a>
                    </figcaption>
                </figure>
                <figure className="effect-apollo millno8">
                    <Image source="https://austincameron.com/res/images/all_work/MillNo8_Work.jpg" />
                    <figcaption>
                        <h2><span>Mill No. 8</span></h2>
                        <p>A Fresh E-Commerce Build Delivering World Class Style in a Custom Fit.</p>
                        <a href="https://millno8.com/" target="_blank" rel="noopener noreferrer">View more</a>
                    </figcaption>
                </figure>
                <div className="clear"></div>
            </div>
                </section>
        )
    }
}


export default FeaturedWork;