import React, { Component } from 'react';
import './TiltedMenu.css';

class TiltedMenu extends Component {
    render() {
        return (
            <div id="tilted_menu" className={ this.props.show ? "tilted_menu active" : "tilted_menu" }>
                <div className="menu_content">
                    <ul>
                        <li><a className="active" href="https://austincameron.com/">Home</a></li>
                        <li><a className="" href="https://austincameron.com/about/">An Introduction</a></li>
                        <li><a className="" href="https://austincameron.com/client_work/">Recent Client Work</a></li>
                        <li><a className="" href="https://austincameron.com/photography/">Buy My Photography</a></li>
                        <li><a className="hide " href="https://austincameron.com/projects/">Startups &amp; Projects</a></li>
                        <li><a className="" href="https://livewarm.co" target="_blank" rel="noopener noreferrer">My Latest Project</a></li>
                        <li><a href="mailto:me@austincameron.com">Let's Chat</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default TiltedMenu;
